import { Addchart } from '@mui/icons-material';
import CachedIcon from '@mui/icons-material/Cached';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Slider,
  Stack,
  TextField,
} from '@mui/material';

import { ActionButton } from '../../../ActionButton';
import { useBrandingManager } from './hooks/useBrandingManager';
import ImageInput from './ImageInput';
import { Branding } from './QRDesign';

interface CustomDesignProps {
  brandings: Branding[] | undefined;
  apiUrl: string;
  siteId: string;
}

const CustomDesign = ({ brandings, apiUrl, siteId }: CustomDesignProps) => {
  const defaultBrandingId = '52add555-843e-42cd-bba0-b2706e8d2f3b';
  const {
    generatePreview,
    previewImage,
    createNewBranding,
    saveBranding,
    deleteBranding,
    brandingChanged,
    handleChange,
    closeDialog,
    handleDeleteButton,
    iconSizeChanged,
    textSizeChanged,
    fetchPdf,
    handleQrCodesToPrintChange,
    handleUpdatePreview,
    closePreview,
    previewUrl,
    showPreview,
    isReadOnly,
    localBranding,
    setLocalBranding,
    selectedBranding,
    showConfirmDelete,
    setUsePrintingGuides,
    printPng,
    usePrintingGuides,
    qrCodesToPrint,
    setPrintPng,
  } = useBrandingManager({
    brandings,
    defaultBrandingId,
    apiUrl,
    siteId,
  });

  return (
    <>
      <Stack direction="row" gap={2}>
        <Box sx={{ flex: '1' }}>
          <FormControl fullWidth>
            <FormLabel>Select a branding (or create new)</FormLabel>
            <ButtonGroup fullWidth>
              <Select
                sx={{ flex: '1 1 auto', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                size="small"
                value={selectedBranding?.id || ''}
                onChange={brandingChanged}
                fullWidth>
                {brandings?.map((d) => {
                  return (
                    <MenuItem key={d.id as string} value={d.id as string}>
                      {d?.name?.length > 0 ? d.name : 'Unnamed branding'}
                    </MenuItem>
                  );
                })}
              </Select>
              <Button
                sx={{ width: 'auto' }}
                onClick={createNewBranding}
                color="info"
                aria-label="Create new template"
                endIcon={<Addchart />}>
                New
              </Button>
            </ButtonGroup>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <TextField
              disabled={isReadOnly}
              size="small"
              fullWidth
              label="Name"
              onChange={handleChange('name')}
              onBlur={(e) => {
                if (e.target.value.length < 1) {
                  setLocalBranding({ ...localBranding, name: 'Unnamed branding' } as Branding);
                }
              }}
              value={localBranding?.name || ''}></TextField>
          </FormControl>
          <Stack direction="row" gap={2} sx={{ mt: 2 }}>
            <FormControl sx={{ flex: '1 1 50%' }}>
              <TextField
                disabled={isReadOnly}
                inputProps={{ type: 'color' }}
                size="small"
                fullWidth
                label="Background color"
                onChange={handleChange('backgroundColor')}
                value={localBranding?.backgroundColor || ''}></TextField>
            </FormControl>
            <FormControl fullWidth sx={{ flex: '1 1 50%' }}>
              <TextField
                disabled={isReadOnly}
                inputProps={{ type: 'color' }}
                size="small"
                fullWidth
                onChange={handleChange('foregroundColor')}
                label="Foreground color"
                value={localBranding?.foregroundColor || ''}></TextField>
            </FormControl>
          </Stack>
          <Stack direction="row" gap={2} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <TextField
                disabled={isReadOnly}
                inputProps={{ type: 'color' }}
                size="small"
                fullWidth
                onChange={handleChange('topBorderBranding.borderColor')}
                label="Top border color"
                value={localBranding?.topBorderBranding.borderColor || ''}></TextField>
            </FormControl>
            <FormControl fullWidth>
              <TextField
                disabled={isReadOnly}
                inputProps={{ type: 'color' }}
                size="small"
                fullWidth
                onChange={handleChange('topBorderBranding.textColor')}
                label="Text color"
                value={localBranding?.topBorderBranding.textColor || ''}></TextField>
            </FormControl>

            <FormControl fullWidth>
              <TextField
                disabled={isReadOnly}
                inputProps={{ type: 'color' }}
                size="small"
                fullWidth
                onChange={handleChange('bottomBorderBranding.borderColor')}
                label="Bottom border color"
                value={localBranding?.bottomBorderBranding.borderColor || ''}></TextField>
            </FormControl>
            <FormControl fullWidth>
              <TextField
                disabled={isReadOnly}
                inputProps={{ type: 'color' }}
                size="small"
                fullWidth
                onChange={handleChange('bottomBorderBranding.textColor')}
                label="Text color"
                value={localBranding?.bottomBorderBranding.textColor || ''}></TextField>
            </FormControl>
          </Stack>
          <Stack direction="row" gap={2} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <FormLabel>Top text size</FormLabel>
              <Slider
                min={20}
                step={1}
                max={100}
                valueLabelDisplay="auto"
                disabled={isReadOnly}
                aria-label="Top text size"
                value={localBranding?.topBorderBranding.textSize || 65}
                onChange={textSizeChanged('topBorderBranding')}
                color="info"
              />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel>Bottom text size</FormLabel>
              <Slider
                min={20}
                step={1}
                max={100}
                valueLabelDisplay="auto"
                disabled={isReadOnly}
                aria-label="Bottom text size"
                value={localBranding?.bottomBorderBranding.textSize || 65}
                onChange={textSizeChanged('bottomBorderBranding')}
                color="info"
              />
            </FormControl>
          </Stack>
          <Stack direction="row" gap={2} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <TextField
                disabled={isReadOnly}
                size="small"
                fullWidth
                onChange={handleChange('topBorderBranding.text')}
                label="Top border text"
                value={localBranding?.topBorderBranding.text || ''}></TextField>
            </FormControl>
            <FormControl fullWidth>
              <TextField
                disabled={isReadOnly}
                size="small"
                fullWidth
                onChange={handleChange('bottomBorderBranding.text')}
                label="Bottom border text"
                value={localBranding?.bottomBorderBranding.text || ''}></TextField>
            </FormControl>
          </Stack>
          <Stack direction="row" gap={2} sx={{ mt: 2 }}>
            <Box sx={{ flex: 1 }}>
              <ImageInput
                updatePreview={handleUpdatePreview}
                isReadOnly={isReadOnly}
                imageUrl={localBranding?.topBorderBranding.imageUrl || ''}
                label={'Top border image'}
                onChange={handleChange('topBorderBranding.imageUrl')}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <ImageInput
                updatePreview={handleUpdatePreview}
                isReadOnly={isReadOnly}
                imageUrl={localBranding?.bottomBorderBranding.imageUrl || ''}
                label={'Bottom border image'}
                onChange={handleChange('bottomBorderBranding.imageUrl')}
              />
            </Box>
          </Stack>
          <Stack direction="row" gap={2} sx={{ mt: 2 }}>
            <Box sx={{ flex: 1 }}>
              <FormControl fullWidth>
                <FormLabel>Icon size</FormLabel>
                <Slider
                  min={4}
                  step={1}
                  max={24}
                  valueLabelDisplay="auto"
                  disabled={isReadOnly}
                  aria-label="Icon size"
                  value={localBranding?.iconSize || 4}
                  onChange={iconSizeChanged}
                  color="info"
                />
              </FormControl>
            </Box>
            <Box sx={{ flex: 1 }}>
              <ImageInput
                updatePreview={handleUpdatePreview}
                isReadOnly={isReadOnly}
                imageUrl={localBranding?.iconUrl || ''}
                label={'Icon url'}
                onChange={handleChange('iconUrl')}
              />
            </Box>
          </Stack>
          <Stack direction="row" justifyContent="flex-end" alignItems="flex-end">
            <Box sx={{ flex: 1 }}>
              <FormControl fullWidth>
                <FormLabel>
                  <Checkbox
                    value={usePrintingGuides}
                    onChange={(d) => setUsePrintingGuides(d.target.checked)}
                  />
                  Use printing guides
                </FormLabel>
              </FormControl>
            </Box>
            <Box sx={{ flex: 1 }}>
              <FormControl fullWidth>
                <FormLabel>
                  <Checkbox
                    value={printPng}
                    disabled={qrCodesToPrint.split('\n').length > 1}
                    onChange={(d) => setPrintPng(d.target.checked)}
                  />
                  Print using png
                </FormLabel>
              </FormControl>
            </Box>
          </Stack>
          <Box display="flex" justifyContent="flex-end">
            <Button
              onClick={() => {
                localBranding && void generatePreview(localBranding);
              }}
              variant="contained"
              color="info"
              startIcon={<CachedIcon />}>
              Update preview
            </Button>
          </Box>
        </Box>
        <Box sx={{ flex: '1' }}>
          <Stack direction="column" gap={2}>
            <FormControl sx={{ flex: '1' }}>
              <FormLabel>Selected qr codes to print</FormLabel>
              <TextField
                multiline={true}
                size="small"
                fullWidth
                id="selected-qr-codes"
                maxRows={6}
                onChange={handleQrCodesToPrintChange}
                value={qrCodesToPrint}
              />
            </FormControl>
            <Box
              sx={{
                flex: '1',
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
              }}>
              <img src={previewImage} style={{ aspectRatio: '69/97', maxWidth: '50%' }} />
            </Box>
          </Stack>
        </Box>
      </Stack>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ mt: 2 }}>
        <Stack direction="row" gap={2} sx={{ mt: 2 }}>
          <Stack direction="row" gap={2} flex="1">
            {!isReadOnly && (
              <Box sx={{ alignSelf: 'flex-start' }} display="flex">
                <Button
                  onClick={handleDeleteButton}
                  variant="contained"
                  color="error"
                  startIcon={<DeleteForeverIcon />}>
                  Delete
                </Button>
              </Box>
            )}

            {!isReadOnly && (
              <Box justifyContent="flex-end" display="flex" flex="1">
                <Button onClick={saveBranding} variant="contained" color="success">
                  Save branding
                </Button>
              </Box>
            )}
          </Stack>
          <Box flex="1" alignItems="flex-end" justifyContent="flex-end" display="flex">
            <ActionButton
              action={async () => selectedBranding && fetchPdf(selectedBranding)}
              variant="contained"
              color="info"
              disabled={qrCodesToPrint.length < 1}>
              Generate codes
            </ActionButton>
          </Box>
        </Stack>
      </Box>
      <Dialog
        open={showConfirmDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-discription">
        <DialogTitle id="alert-dialog-title">Delete branding</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this branding, it can't be undone.
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="warning"
            onClick={() => localBranding && deleteBranding()}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        transitionDuration={{ appear: 100, enter: 100, exit: 300 }}
        open={showPreview}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-discription"
        onClick={closePreview}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0,0,0,0.8)' }}>
        <img src={previewUrl} alt="QR Code Preview" style={{ maxWidth: '80%', maxHeight: '80%' }} />
      </Backdrop>
    </>
  );
};

export default CustomDesign;
