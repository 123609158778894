import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import ReorderIcon from '@mui/icons-material/Reorder';
import { TableCell, TableRow } from '@mui/material';
import { PropsWithChildren } from 'react';
interface SortableItemProps {
  id: string;
  key: string;
  colspan: number;
}
const SortableItem = ({ id, children, colspan }: PropsWithChildren<SortableItemProps>) => {
  const { attributes, listeners, setNodeRef, transform, isDragging, transition } = useSortable({
    id,
  });

  const sx = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  };

  return (
    <TableRow ref={setNodeRef} sx={sx}>
      {isDragging ? (
        <TableCell
          sx={{ background: 'rgba(127, 207, 250, 0.3)', border: 'none', height: '74px' }}
          colSpan={colspan}></TableCell>
      ) : (
        <>
          {children}
          <TableCell sx={{ cursor: 'grab' }} {...attributes} {...listeners}>
            <ReorderIcon />
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default SortableItem;
