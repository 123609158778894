import InputIcon from '@mui/icons-material/Input';
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { CustomFieldCategory, CustomFieldMapping, categoryTypes } from './CustomFields';

export interface MappingProperties {
  id: string;
  description: string;
  title: string;
  type: string;
}

interface MappingsProps {
  mappings: CustomFieldMapping[] | undefined;
  categories: Record<string, CustomFieldCategory[]>;
  onChange: (mappings: CustomFieldMapping[]) => void;
}

const Mappings = ({ mappings, categories, onChange }: MappingsProps) => {
  const { enqueueSnackbar: showSnack } = useSnackbar();

  const [localMappings, _setLocalMappings] = useState<CustomFieldMapping[]>(mappings || []);
  const setLocalMappings = (lMappings: CustomFieldMapping[]) => {
    lMappings.sort((a, b) => {
      return a.title > b.title ? 1 : -1;
    });
    _setLocalMappings(lMappings);
    onChange(lMappings);
  };

  const [newMappingType, setNewMappingType] = useState('-1');
  const [newMappingCategory, setNewMappingCategory] = useState<string>('-1');
  const [newMappingProperties, setNewMappingProperties] = useState<MappingProperties[] | null>([]);
  const [newMappingProperty, setNewMappingProperty] = useState<string[]>([]);

  const handleTypeChange = (e: SelectChangeEvent<string>) => {
    setNewMappingType(e.target.value);
    setNewMappingCategory('-1');
    setNewMappingProperties(null);
    setNewMappingProperty([]);
  };

  const handleCategoryChange = (e: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = e;
    const newSelected = categories[newMappingType].find((cat) => cat.id === value);
    setNewMappingProperties(null);
    setNewMappingProperty([]);
    if (newSelected?.properties) {
      setNewMappingProperties(newSelected.properties);
    }
    setNewMappingCategory(newSelected?.id || '-1');
  };

  const handlePropertyChange = (e: SelectChangeEvent<string[]>) => {
    setNewMappingProperty(e.target.value as string[]);
  };

  const transferMappings = () => {
    let associatedType: string;
    const tmpMappings = [...localMappings];
    if (newMappingProperty.length > 0) {
      associatedType = 'Property';
      newMappingProperty.forEach((prop) => {
        const currentCat = categories[newMappingType].find((cat) => cat.id === newMappingCategory);
        if (currentCat) {
          const newProp = currentCat.properties.find((p) => p.id === prop);
          if (newProp) {
            const ex = localMappings?.find((pp) => pp.associatedId === newProp.id);
            if (!ex) {
              tmpMappings.push({ associatedType, associatedId: newProp.id, title: newProp.title });
            }
          }
        }
      });
      setLocalMappings(tmpMappings);
      setNewMappingProperties([]);
      setNewMappingProperty([]);
    } else if (newMappingCategory !== '-1') {
      associatedType = 'Category';
      const newCat = categories[newMappingType].find((cat) => cat.id === newMappingCategory);
      if (newCat) {
        const ex = localMappings?.find((type) => type.associatedId === newCat.id);
        if (!ex) {
          tmpMappings?.push({ associatedType, associatedId: newCat.id, title: newCat.title });
          setLocalMappings(tmpMappings);
          setNewMappingCategory('-1');
          setNewMappingProperties([]);
        } else {
          showSnack('This category is already mapped.', { variant: 'warning' });
        }
      } else {
        showSnack('Could not find a category to map, something went wrong. Try again.', {
          variant: 'error',
        });
      }
    } else if (newMappingType !== '-1') {
      associatedType = 'Type';
      const newType = categoryTypes.find((type) => type.key === newMappingType);
      if (newType) {
        const ex = tmpMappings?.find((t) => t.associatedId === newType.id);
        if (!ex) {
          tmpMappings?.push({ associatedType, associatedId: newType.id, title: newType.value });
          setLocalMappings(tmpMappings);
          setNewMappingType('-1');
          setNewMappingCategory('-1');
          setNewMappingProperties([]);
        } else {
          showSnack('The type is already mapped.', { variant: 'warning' });
        }
      } else {
        showSnack('Could not find a type to map, something went wrong. Try again.', {
          variant: 'error',
        });
      }
    } else {
      showSnack('You need to choose what to map before trying to map it.', { variant: 'warning' });
    }
  };

  const removeMapping = (aId: string) => {
    const tmp = localMappings.filter((a) => a.associatedId !== aId);
    setLocalMappings(tmp);
  };

  return (
    <Box sx={{ my: 2 }}>
      <Stack direction="row">
        <Box sx={{ flex: '0 0 50%' }}>
          <Typography component="span" variant="subtitle1" sx={{ display: 'block' }}>
            Select mappings
          </Typography>
          <Stack direction="row" alignItems="center">
            <Box sx={{ flex: '1 1 auto' }}>
              <InputLabel htmlFor="type-select">Type</InputLabel>
              <FormControl sx={{ minWidth: '300px' }}>
                <Select id="type-select" size="small" value={newMappingType} onChange={handleTypeChange}>
                  {categoryTypes.map(({ key, value, id }) => {
                    const ex = localMappings.find((p) => id === p.associatedId);
                    if (ex) {
                      return null;
                    }
                    return (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {newMappingType !== '-1' && categories[newMappingType] && (
                <>
                  <InputLabel htmlFor="category-select">Category</InputLabel>
                  <FormControl sx={{ minWidth: '300px' }}>
                    <Select
                      size="small"
                      value={newMappingCategory}
                      onChange={handleCategoryChange}
                      id="category-select">
                      <MenuItem key="category-unselected" value="-1">
                        Choose a category
                      </MenuItem>
                      {categories[newMappingType].map((cat) => {
                        const ex = localMappings.find((p) => cat.id === p.associatedId);
                        if (ex) {
                          return null;
                        }
                        return (
                          <MenuItem key={cat.id} value={cat.id}>
                            {cat.title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </>
              )}
              {newMappingProperties && newMappingProperties.length > 0 && (
                <>
                  <InputLabel htmlFor="category-property">Properties</InputLabel>
                  <FormControl sx={{ minWidth: '300px' }}>
                    <Select
                      size="small"
                      input={<OutlinedInput label="Tag" />}
                      value={newMappingProperty}
                      onChange={handlePropertyChange}
                      multiple
                      renderValue={(selected) => `${selected.length} selected`}
                      id="category-select">
                      {newMappingProperties.map((prop) => {
                        const ex = localMappings.find((p) => prop.id === p.associatedId);
                        if (ex) {
                          return null;
                        }
                        return (
                          <MenuItem key={prop.id} value={prop.id}>
                            <Checkbox
                              checked={
                                newMappingProperty.filter((d) => {
                                  return prop.id === d;
                                }).length > 0
                              }
                            />
                            <ListItemText primary={prop.title} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </>
              )}
            </Box>
            <Box sx={{ flex: '0 1 auto' }}>
              <IconButton aria-label="Add to mappings" color="error" sx={{ ml: 1 }} onClick={transferMappings}>
                <InputIcon />
              </IconButton>
            </Box>
          </Stack>
        </Box>
        <Box sx={{ flex: '0 0 50%' }}>
          <Typography component="span" variant="subtitle1" sx={{ display: 'block' }}>
            Existing mappings
          </Typography>
          <Box sx={{ flex: '1 1 auto' }}>
            <Stack direction="row" spacing={0} sx={{ flexWrap: 'wrap' }}>
              {localMappings?.map((d) => {
                return (
                  <Chip
                    key={`${d.associatedId}`}
                    label={d.title}
                    variant="outlined"
                    onDelete={() => removeMapping(d.associatedId)}
                    sx={{ mb: 1, mr: 1 }}
                  />
                );
              })}
            </Stack>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default Mappings;
