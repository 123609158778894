import { Box, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { Order, TableValue } from '.';
import { useMemo } from 'react';
import { visuallyHidden } from '@mui/utils';

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

interface TableHeadProps {
  value: TableValue;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const TableHeadComponent = ({ value, order, orderBy, onRequestSort }: TableHeadProps) => {
  const headCells = useMemo(
    () =>
      value.columns.map(
        (x) =>
          ({
            id: x.key,
            label: x.name,
            disablePadding: false,
            numeric: false,
          }) as HeadCell,
      ),
    [value.columns],
  );

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeadComponent;
