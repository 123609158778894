import React from 'react';

import { ComponentBase } from './ComponentChooser';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styled from '@emotion/styled';
import { Theme, useTheme } from '@mui/material';

interface LabelProps extends ComponentBase {
  comp: { value: string };
}

const MarkdownDisplay = ({ comp: { value } }: LabelProps) => {
  const theme = useTheme();

  return (
    <StyleWrapper theme={theme}>
      <ReactMarkdown
        components={{
          h1: 'h2',
          h2: 'h3',
          h3: 'h4',
          h4: 'h5',
          h5: 'h5',
          h6: 'h5',
        }}
        remarkPlugins={[remarkGfm]}>
        {value}
      </ReactMarkdown>
    </StyleWrapper>
  );
};

interface StyleWrapperProps {
  theme: Theme;
}

const StyleWrapper = styled.div<StyleWrapperProps>`
  p {
    margin: 8px 0;
    &.preamble {
      margin-top: 0;
      font-size: calc(1.2 * 1.125rem);
    }
  }
  a {
    text-decoration: underline;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 32px 0 16px;
  }
  ul,
  ol {
    list-style: initial;
    padding-left: 32px;
  }
  li {
    margin: 4px 0;
  }
  pre {
    overflow: auto;
    padding: 16px;

    font-family:
      ui-monospace,
      SFMono-Regular,
      SF Mono,
      Menlo,
      Consolas,
      Liberation Mono,
      monospace;
  }
  code {
    font-size: calc(0.85 * 1em);
    background: ${(props) => props.theme.palette.secondary.light};
    padding: 4px;
    border-radius: 4px;
    font-family:
      ui-monospace,
      SFMono-Regular,
      SF Mono,
      Menlo,
      Consolas,
      Liberation Mono,
      monospace;
  }
  img {
    max-width: 100%;
  }
  strong {
    font-weight: 900;
  }

  table {
    border-collapse: collapse;
    font-family: Tahoma, Geneva, sans-serif;
  }

  table td {
    padding: 8px 16px;
  }

  table thead th {
    background-color: ${(props) => props.theme.palette.background.paper};
    color: ${(props) => props.theme.palette.primary.contrastText};
    font-weight: bold;
    border: 1px solid ${(props) => props.theme.old.borderColor};
    padding: 8px 16px;
  }

  table tbody td {
    color: ${(props) => props.theme.palette.primary.contrastText};
    border: 1px solid ${(props) => props.theme.old.borderColor};
  }

  table tbody tr {
    background-color: ${(props) => props.theme.palette.background.paper};
  }

  table tbody tr:nth-child(odd) {
    background-color: ${(props) => props.theme.palette.background.default};
  }
`;

export default MarkdownDisplay;
