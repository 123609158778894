import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
} from '@mui/material';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { TabPanel } from '../../Section';
import { SRMetaData } from '../Metadata/Metadata';
import { SRLocation } from './api';
import BasicInfo from './Basicinfo';
import Metadata from './Metadata';

export interface ILocationTabContext {
  baseData: SRLocation;
  setBaseData: React.Dispatch<React.SetStateAction<SRLocation>>;
  metaData: SRMetaData[];
  hasError: boolean;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
}

const LocationTabContext = createContext<ILocationTabContext | null>(null);

export const useLocationTabContext = () => {
  const ctx = useContext(LocationTabContext);
  if (ctx === null) {
    throw new Error('useLocationTabContext need to be inside a LocationTabContext');
  }
  return ctx;
};

interface EditLocationFormProps {
  show: boolean;
  location: SRLocation | null;
  parentId: string | null;
  onSave: (data: SRLocation) => void;
  onCancel: () => void;
}
const AddEditLocationForm = ({
  show,
  location,
  parentId = null,
  onSave,
  onCancel,
}: EditLocationFormProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const [locationId, setLocationId] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const [tab, setTab] = useState<number>(0);
  const [baseData, setBaseData] = useState<SRLocation>({} as SRLocation);

  const handleSave = () => {
    setIsSaving(true);
    if (
      baseData.title.length < 1 ||
      baseData.externalId.length < 1 ||
      baseData.locationType.length < 1
    ) {
      setHasError(true);
    } else {
      const metadata = baseData.metadata.map((md) => md.id as string) || [];
      const data = {
        ...baseData,
        value: location?.value ? [...location.value] : [],
        metadataIds: metadata,
      };
      onSave(data);
    }
    setIsSaving(false);
  };

  const handleTabChange = (e: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  useEffect(() => {
    const descriptionRequiredInitial = location?.descriptionRequired ?? false;
    const tmpLocation: SRLocation = {
      id: location?.id || '',
      parentId: parentId || location?.parentId || null,
      externalId: location?.externalId || '',
      externalSiteId: location?.externalSiteId || '',
      title: location?.title || '',
      locationType: location?.locationType || '39266d8e-311c-4cb5-9759-73928a55bd1a',
      isSelectable: location?.isSelectable ?? false,
      descriptionRequired: descriptionRequiredInitial,
      isHidden: location?.isHidden ?? false,
      value: [],
      metadata: location?.metadata || [],
    };
    setLocationId(location?.id || '');
    setBaseData(tmpLocation);
  }, [location]);

  if (!location) {
    return null;
  }

  return (
    <LocationTabContext.Provider
      value={{ baseData, setBaseData, metaData: baseData.metadata, hasError, setHasError }}>
      <Dialog
        open={show}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-discription"
        fullWidth={true}
        maxWidth="lg">
        <DialogTitle id="alert-dialog-title">{`${
          locationId ? 'Edit' : 'Add'
        } a location`}</DialogTitle>
        <DialogContent>
          <Box component="form">
            <Tabs value={tab} onChange={handleTabChange}>
              <Tab label="Basic information" id={'tab-0'} aria-controls="tab-0" />
              <Tab label="Meta data" id={'tab-0'} aria-controls="tab-0" />
            </Tabs>
            <TabPanel value={tab} index={0}>
              <BasicInfo />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Metadata />
            </TabPanel>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              onCancel();
            }}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="success"
            onClick={handleSave}
            disabled={isSaving || hasError}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </LocationTabContext.Provider>
  );
};

export default AddEditLocationForm;
