import axios, { AxiosRequestHeaders, AxiosResponseHeaders } from 'axios';
import fileDownload from 'js-file-download';
import { useMutation, useQuery } from 'react-query';

import { useParams } from 'react-router-dom';
import { useGetHeaders } from '../../contexts/userContext';
import { ServiceInfo } from '/src/types/site';

export const baseUrl = process.env.REACT_APP_BACKEND_API_URL;
export const baseUrlBAM = process.env.REACT_APP_BAM_URL;
export const accessToken = 'access_token';

export const getCommonParams = (serviceType: string) => {
  const headers = useGetHeaders(accessToken) as AxiosRequestHeaders;
  const siteId = useParams().siteId as string;
  const { data: serviceInfo } = useGetServiceInfo<ServiceInfo>(serviceType);
  return { headers, siteId, serviceInfo };
};

export const useGetConfiguration = (url: string) => {
  const headers = useGetHeaders(accessToken) as AxiosRequestHeaders;

  return useQuery<ServiceInfo>(
    ['serviceConfiguration', url],
    async () => {
      const req = await axios.get<ServiceInfo>(`${baseUrl}${url}`, { headers });
      return req.data;
    },
    { enabled: !!url && headers !== null },
  );
};

export const useUpdateConfigurationMutation = () => {
  const headers = useGetHeaders(accessToken) as AxiosRequestHeaders;

  return useMutation(async ({ data, url }: { data: unknown; url: string }) => {
    return axios.post(`${baseUrl}${url}`, data, { headers });
  });
};

export const useGetServiceInfo = <T>(serviceId: string) => {
  const headers = useGetHeaders(accessToken) as AxiosRequestHeaders;

  return useQuery<T>(
    ['serviceConfig', serviceId],
    async () => {
      const req = await axios.get<T>(`${baseUrlBAM}/Service/${serviceId}`, { headers });
      return req.data;
    },
    { enabled: headers !== null },
  );
};

export const useFileDownloadResponseHandler = () => {
  const headers = useGetHeaders(accessToken) as AxiosRequestHeaders;

  const getFilenameFromHeaders = (responseHeaders: AxiosResponseHeaders) => {
    const contentDisposition = responseHeaders['content-disposition'];
    if (!contentDisposition) {
      return undefined;
    }

    const parts = contentDisposition.split(';');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const fields = Object.fromEntries(parts.map((x) => x.split('=').map((p) => p?.trim() ?? '')));
    // eslint-disable-next-line
    return fields?.filename as string;
  };

  return async ({
    data,
    url,
    fallbackName,
  }: {
    data: unknown;
    url: string;
    fallbackName: string;
  }) => {
    const response = await axios.post<string>(`${baseUrl}${url}`, data, { headers });
    fileDownload(response.data, getFilenameFromHeaders(response.headers) ?? fallbackName);
  };
};

export default useGetConfiguration;
