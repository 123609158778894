import { Autocomplete, Box, FormControl, MenuItem, TextField } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SRLocation, useSearchLocation } from './ServiceRequest/Locations/api';

export interface SelectedValue {
  value: string;
  label: string;
  item: ServiceRequestLocation;
}

export interface ServiceRequestLocation {
  id: string;
  title: string;
  externalId: string;
  value: ServiceRequestLocation[];
  locationType: string;
  descriptionRequired: boolean;
  isSelectable: boolean;
}
export interface LocationData {
  location: SRLocation;
  locationId: string | null;
}
interface LocationProps {
  data: LocationData;
  edit: boolean;
  setHasEdit: Dispatch<SetStateAction<boolean>>;
  onChange?: (value: string) => void;
}
const Location = ({ data, edit, setHasEdit, onChange }: LocationProps) => {
  const { siteId: oSite } = useParams();
  const siteId = oSite as string;

  const [locations, setLocations] = useState<SelectedValue[] | null>(null);
  const [lTimeout, setLTimeout] = useState<NodeJS.Timeout | null>(null);
  const [searchWord, setSearchWord] = useState<string>('');
  const [mappedToItem, setMappedToItem] = useState<SelectedValue | null>(null);

  const { isLoading: isFetching, data: searchResult } = useSearchLocation(siteId, searchWord);

  const formatList = (locs: ServiceRequestLocation[]) => {
    const locationList: SelectedValue[] = [];
    const getValues = (location: ServiceRequestLocation, preval: string) => {
      const label = `${preval} ${location.title}`;

      locationList.push({ value: location.id, label: label, item: location });
      if (location.value?.length > 0) {
        location.value.forEach((v) => getValues(v, `${preval}  `));
      }
    };

    locs.forEach((v) => getValues(v, ''));
    return locationList;
  };

  const searchLocations = (searchString: string) => {
    if (lTimeout) {
      clearTimeout(lTimeout);
    }
    const a = setTimeout(() => {
      setSearchWord(searchString);
    }, 400);
    setLTimeout(a);
  };

  useEffect(() => {
    if (searchResult) {
      setLocations(formatList(searchResult));
    }
  }, [searchResult]);

  useEffect(() => {
    if (data && data?.location) {
      setMappedToItem({
        item: data.location as ServiceRequestLocation,
        label: data.location.title,
        value: data.location.id as string,
      });
    }
  }, [data?.location]);

  if (!edit) {
    if (mappedToItem) {
      return <Box>{mappedToItem.label}</Box>;
    } else {
      return <Box></Box>;
    }
  }

  return (
    <Box>
      <FormControl sx={{ minWidth: '300px' }}>
        <Autocomplete
          sx={{ width: '250px' }}
          size="small"
          noOptionsText="No locations found"
          value={mappedToItem}
          options={(locations as SelectedValue[]) || []}
          filterOptions={(o) => o}
          loading={isFetching}
          renderInput={(params) => {
            params.inputProps.value =
              (params?.inputProps?.value as string)?.replace(/^(\s+)/gis, '') || '';
            params.inputProps.placeholder = 'Type to search for locations...';
            return <TextField {...params} sx={{ whiteSpace: 'normal' }} />;
          }}
          renderOption={(params, item) => {
            return (
              <MenuItem {...params} dense={true}>
                <div style={{ whiteSpace: 'pre' }}>{item.label}</div>
              </MenuItem>
            );
          }}
          getOptionDisabled={(option) => option.item.isSelectable === false}
          onInputChange={(_, value) => {
            searchLocations(value);
          }}
          isOptionEqualToValue={(option, value) => {
            return option.value === value.value;
          }}
          onChange={(_, location) => {
            setHasEdit(true);
            if (location) {
              setMappedToItem(location);
              data.locationId = location.value;
              onChange?.(data.locationId);
            } else {
              setMappedToItem(null);
            }
          }}></Autocomplete>
      </FormControl>
    </Box>
  );
};
export default Location;
