import React from 'react';

import { SchemaComponent } from '../../types/schema';
import CheckBox from './Checkbox';
import Customer from './Customer';
import Hidden from './Hidden';
import Label from './Label';
import List from './List';
import LocationSelect from './LocationSelect';
import Markdown from './Markdown';
import MarkdownDisplay from './MarkdownDisplay';
import MultiLine from './MultiLine';
import MultiSelect from './Multiselect';
import Notice from './Notice';
import Number from './Number';
import ObjectCollection from './ObjectCollection';
import ReadonlyValue from './ReadonlyValue';
import Section from './Section';
import Select from './Select';
import CustomFields from './ServiceRequest/CustomFields/CustomFields';
import Location from './ServiceRequest/Locations/Locations';
import Metadata from './ServiceRequest/Metadata/Metadata';
import QR from './ServiceRequest/QR/QR';
import QRDesign from './ServiceRequest/QR/QRDesign';
import SubPart from './SubPart';
import Table from './Table';
import TextBox from './Textbox';
import WeeklyMenuTest from './WeeklyMenuTest';
export interface ComponentBase {
  id: string;
  comp: SchemaComponent;
  required: boolean;
  component?: string;
  ccOnChange?: (value: SchemaComponent) => void;
}

const Components: Record<string, unknown> = {
  TextBox,
  Label,
  Hidden,
  MultiSelect,
  Select,
  Section,
  CheckBox,
  Location,
  Customer,
  List,
  Markdown,
  ReadonlyValue,
  ObjectCollection,
  MultiLine,
  SubPart,
  Number,
  CustomFields,
  Notice,
  QR,
  QRDesign,
  WeeklyMenuTest,
  LocationSelect,
  Metadata,
  Table,
  MarkdownDisplay,
};

const ComponentChooser = ({ id, comp, required, ...props }: ComponentBase) => {
  const type = comp.type || comp.Type || null;
  if (type === null || type === '$type') {
    return null;
  }
  const newType = type;
  if (typeof Components[newType] !== 'undefined') {
    const key = `${type}_${id}`;
    return React.createElement(Components[type] as React.FunctionComponent<ComponentBase>, {
      id,
      comp,
      key,
      required,
      ...props,
    });
  }
  return React.createElement(
    () => <div className="error">The component {comp.type} does not exist.</div>,
    { id },
  );
};

export default ComponentChooser;
