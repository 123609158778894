import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ReorderIcon from '@mui/icons-material/Reorder';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { CustomFieldSchema, CustomFieldSelectSchema, languages, SelectLangauges } from './CustomFields';
import SortableItem from './SortableItem';

interface SortableProps {
  items: CustomFieldSelectSchema[];
  data: CustomFieldSchema;
  onDeleteSelectValue: (index: number) => void;
  onChangeSelectValues: (index: number, lang: SelectLangauges) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  onUpdateSortOrder: (items: CustomFieldSelectSchema[]) => void;
  addRow: () => void;
}

interface DragItemSchema extends CustomFieldSelectSchema {
  id: string;
}

const Sortable = ({
  items,
  data,
  onChangeSelectValues,
  onDeleteSelectValue,
  onUpdateSortOrder,
  addRow,
}: SortableProps) => {
  const [activeId, setActiveId] = useState<string>('');
  const [lItems, setLItems] = useState<DragItemSchema[]>(items.map((d) => ({ ...d, id: d.key })));
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (state: DragEndEvent) => {
    const { active, over } = state;
    if (over && active.id !== over.id) {
      const oldIndex = lItems.findIndex((d) => d.key === active.id);
      const newIndex = lItems.findIndex((d) => d.key === over.id);
      const newOrder = arrayMove(lItems, oldIndex, newIndex);
      setLItems(newOrder);
      onUpdateSortOrder(newOrder);
    }
    setActiveId('');
  };

  const handleDragStart = (event: DragStartEvent) => {
    if (event) {
      setActiveId(event.active.id as string);
    }
  };

  useEffect(() => {
    setLItems(items.map((d) => ({ ...d, id: d.key })));
  }, [items]);

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis]}>
      <TableContainer
        component={Box}
        sx={{
          mt: 2,
          p: 1,
          backgroundColor: data?.error ? 'rgba(255, 0, 0, 0.05)' : '',
          border: data?.error ? '1px solid #f00' : '',
        }}>
        <Typography component="div" variant="h6">
          Select values
        </Typography>
        <Table sx={{ mb: 2 }}>
          <TableHead>
            <TableRow>
              {languages.map((lang) => {
                return <TableCell key={`lang-key-${lang}`}>{lang}</TableCell>;
              })}
              <TableCell colSpan={2}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <SortableContext items={lItems} strategy={verticalListSortingStrategy}>
              {lItems.map((value, idx) => {
                return (
                  <SortableItem
                    key={`lang-key-values-${value.key || idx}`}
                    id={value.key}
                    colspan={languages.length + 2}>
                    {languages.map((lang) => {
                      return (
                        <TableCell key={`lang-key-values-${lang}`}>
                          <FormControl sx={{ flex: '1 1 auto' }}>
                            <TextField
                              size="small"
                              value={value.translations[lang] || ''}
                              label={`Translation for ${lang}`}
                              placeholder={`Translation for ${lang}`}
                              onChange={onChangeSelectValues(idx, lang)}
                            />
                          </FormControl>
                        </TableCell>
                      );
                    })}
                    <TableCell>
                      <IconButton aria-label="Delete value" color="info" onClick={() => onDeleteSelectValue(idx)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </SortableItem>
                );
              })}
            </SortableContext>
            <TableRow>
              <TableCell colSpan={languages.length + 2}>
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  <Button endIcon={<AddIcon />} variant="contained" color="info" onClick={() => addRow()}>
                    Add new value
                  </Button>
                </Stack>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <DragOverlay dropAnimation={null}>
        {activeId ? (
          <Table key={`lang-key-values-draggable-item-overlay`}>
            <TableBody>
              <TableRow sx={{ backgroundColor: (theme) => theme.palette.background.default }}>
                {languages.map((lang) => {
                  return (
                    <TableCell key={`lang-key-values-${lang}`}>
                      <FormControl sx={{ flex: '1 1 auto' }}>
                        <TextField
                          size="small"
                          value={lItems.find((d) => d.key === activeId)?.translations[lang] || ''}
                          label={`Translation for ${lang}`}
                          placeholder={`Translation for ${lang}`}
                        />
                      </FormControl>
                    </TableCell>
                  );
                })}
                <TableCell>
                  <IconButton aria-label="Delete value" color="info">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <ReorderIcon />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default Sortable;
