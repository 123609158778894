import axios, { AxiosError, AxiosRequestHeaders } from 'axios';
import { useMutation, useQuery } from 'react-query';

import { useGetHeaders, useUserContext } from '../../contexts/userContext';
import { PartInfo, Service, ServiceInfo, Site } from '../../types/site';
import { baseUrlBAM as bamUrl, baseUrl } from '../serviceConfiguration/api';

export const headerType = 'access_token';

export const useGetSites = () => {
  const headers = useGetHeaders(headerType) as AxiosRequestHeaders;

  return useQuery<Site[]>(
    'getSites',
    async () => {
      const req = await axios.get<Site[]>(`${bamUrl}/Site`, {
        headers,
      });
      return req.data;
    },
    { enabled: headers !== null },
  );
};

export const getSite = (siteId: string) => {
  const headers = useGetHeaders(headerType) as AxiosRequestHeaders;

  return useQuery<Site>(
    ['getSite', siteId],
    async ({ queryKey }) => {
      const [, siteId] = queryKey;
      const res = await axios.get<Site>(`${bamUrl}/Site/${String(siteId)}`, { headers });
      return res.data;
    },
    { enabled: !!siteId && headers !== null },
  );
};

export const useAddSite = () => {
  const { setCurrentSite } = useUserContext();
  const headers = useGetHeaders(headerType) as AxiosRequestHeaders;

  return useMutation(async (data: Site) => {
    const res = await axios.post<Site>(`${bamUrl}/Site`, data, { headers });
    if (res.data.id) {
      setCurrentSite(res.data.id);
    }
    return res;
  });
};

export const updateSite = () => {
  const headers = useGetHeaders(headerType) as AxiosRequestHeaders;

  return useMutation(async (data: Site) => {
    return axios.put(`${bamUrl}/Site/${data.id}`, data, { headers });
  });
};

export const useDeleteSite = () => {
  const { setCurrentSite } = useUserContext();
  const headers = useGetHeaders(headerType) as AxiosRequestHeaders;

  return useMutation(async (id: string) => {
    const req = await axios.delete(`${bamUrl}/Site/${id}`, { headers });
    setCurrentSite('-1');
    return req;
  });
};

export const getServices = () => {
  const headers = useGetHeaders(headerType) as AxiosRequestHeaders;

  return useQuery<Service[]>(
    'getServices',
    async () => {
      const req = await axios.get<Service[]>(`${bamUrl}/Service`, {
        headers,
      });
      if (req.status > 399) {
        throw new Error('Could not fetch services');
      }
      return req.data;
    },
    { enabled: headers !== null },
  );
};

export const getServicesForContract = (ownerId: string) => {
  const headers = useGetHeaders(headerType) as AxiosRequestHeaders;

  return useQuery<Service[]>(
    ['getServiceForContract', ownerId],
    async () => {
      const req = await axios.get<Service[]>(`${bamUrl}/Service/customer/${ownerId}`, {
        headers,
      });
      return req.data;
    },
    { enabled: ownerId !== '-1' && headers !== null },
  );
};

export interface ServiceInfoWithSubpages extends ServiceInfo {
  subPages: PartInfo[];
}

export const useGetServiceForSite = (siteId: string) => {
  const headers = useGetHeaders(headerType) as AxiosRequestHeaders;

  return useQuery<ServiceInfoWithSubpages[]>(
    ['useGetServiceForSite', siteId],
    async () => {
      const { data } = await axios.get<ServiceInfo[]>(`${bamUrl}/Service/site/${siteId}`, {
        headers,
      });

      const retData = await Promise.all(
        data.map(async (serv) => {
          const getPartInfo = async () => {
            try {
              // First check if we can access the parts endpoint while providing siteId
              const initialUrl = `${baseUrl}${serv.basePath}${serv.configurationPath}/parts/${siteId}`;
              const { data } = await axios.get<PartInfo[]>(initialUrl, { headers });
              return data;
            } catch (error) {
              if ((error as AxiosError).response?.status === 404) {
                // ...Endpoint with siteId not yet implemented, try without
                const fallbackUrl = `${baseUrl}${serv.basePath}${serv.configurationPath}/parts`;
                const { data: dataFromFallback } = await axios.get<PartInfo[]>(fallbackUrl, {
                  headers,
                });
                return dataFromFallback;
              }

              throw error;
            }
          };

          try {
            const subPages = await getPartInfo();
            return Promise.resolve<ServiceInfoWithSubpages>({ ...serv, subPages });
          } catch (e) {
            return Promise.resolve<ServiceInfoWithSubpages>({ ...serv, subPages: [] });
          }
        }),
      );
      return retData;
    },
    { enabled: !!siteId && siteId !== '-1' && headers !== null },
  );
};

export default {
  useGetSites,
  getSite,
  useAddSite,
  updateSite,
  useDeleteSite,
  getServicesForContract,
  useGetServiceForSite,
};
