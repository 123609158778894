/* eslint-disable @typescript-eslint/no-non-null-assertion */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import { createContext } from 'react';

import {
  ConfigurationPagesType,
  ConfigurationSection,
  OnChangeValueType,
  SchemaArray,
  SchemaComponent,
  SchemaComponentWithKey,
  SchemaObject,
} from '../types/schema';

export interface FormContextType {
  handleChange: (id: string, value: OnChangeValueType, replace?: boolean) => void;
  setFormIsDirty: (isDirty: boolean) => void;
  setDirty?: () => void;
}
export const FormContext = createContext<FormContextType>({
  handleChange: () => {},
  setFormIsDirty: () => {},
});

// Object { key: "HappyFox", value: {…} }
export const GetPathObject = (path: string, obj: SchemaComponentWithKey | SchemaArray[] | SchemaComponent) => {
  const reg = /\[(\d+)\]/gm;
  return path
    .split('.')
    .reduce<SchemaComponentWithKey | SchemaComponentWithKey[] | SchemaArray[] | SchemaComponent>(
      (prev, curr: string, index: number, arr: string[]) => {
        const rprev = prev as unknown as Record<string, SchemaComponentWithKey>;
        if (curr === 'configurationPages') {
          const key = arr[index + 1];
          const d = rprev[curr] as unknown as SchemaArray[];
          const v = d.find((a) => a.key === key)!;
          // Remove next index from array since we return the value from the key/value array in the object.
          arr.splice(index, 1);
          return v.value;
        }
        if (curr.match(reg)) {
          // Find index in array to update from id.
          const grps = reg.exec(curr);
          if (grps) {
            return rprev[grps[1]];
          }
          return prev;
        }
        return rprev[curr];
      },
      obj,
    );
};

const ignoreTypes = ['configurationEndpoint', 'configurationEndpoint'];
const saveTypes = [
  'checkbox',
  'multiselect',
  'select',
  'textbox',
  'radiobox',
  'hidden',
  'markdown',
  'number',
  'locationselect',
  'metadata',
];
const objectTypes = ['location', 'customer'];
// Returns a copy of original data formatted for sending to backend

export const GetFormData = (obj: SchemaObject | SchemaComponent) => {
  const newObj: Record<string, unknown> = {};
  const keys = Object.keys(obj);
  keys.forEach((key) => {
    const lKey = key as keyof SchemaComponent;
    if (typeof obj[lKey] === 'object') {
      const lobj = obj[lKey] as SchemaComponent;
      if (lobj.type) {
        const type = lobj.type.toLowerCase();
        if (saveTypes.indexOf(type) > -1) {
          // "Primitives" like textbox, select
          newObj[lKey] = lobj.value ?? null;
        } else if (type === 'qr') {
          newObj[lKey] = { value: lobj.value };
        } else if (type === 'list') {
          const { data } = lobj;
          const { data: values } = GetFormData({ data: data! });
          newObj[lKey] = values;
        } else if (objectTypes.includes(type)) {
          const values = lobj.value! as SchemaComponent[];
          const sData = values.map((ldata) => {
            const l = GetFormData(ldata);
            return l;
          });

          newObj[lKey] = {
            value: sData,
          };
        } else if (type === 'customfields') {
          const value = lobj.value as Record<string, string | undefined | null>[];
          const t = value.map((d) => {
            const tmpData = { ...d, mappings: [] };
            const retData = {
              ...GetFormData(tmpData),
              mappings: d.mappings,
              translations: d.translations,
              values: d.values,
            };
            return retData;
          });
          newObj[lKey] = { value: t };
        } else if (type === 'section') {
          const workObj = obj[lKey] as ConfigurationSection;
          const configPages: ConfigurationPagesType[] = [];
          workObj.pages.forEach((keyValue) => {
            const { value } = workObj.configurationPages.find((d) => d.key === keyValue.key)!;
            const newData = GetFormData(value);
            const writeValue: ConfigurationPagesType = {
              value: {
                $type: (value.$type as unknown as { value: string })?.value || '',
                ...newData,
              },
              key: keyValue.key,
            };
            configPages.push(writeValue);
          });
          newObj[lKey] = {
            pages: lobj.pages,
            configurationPages: configPages,
          } as ConfigurationSection;
        } else if (type === 'objectcollection') {
          newObj[lKey] = lobj.value;
        }
      } else if (Array.isArray(obj[lKey])) {
        const tmpArr = (lobj as SchemaComponent[]).map((d) => {
          return GetFormData(d);
        });
        newObj[key] = tmpArr;
      } else {
        if (ignoreTypes.indexOf(key) !== -1) {
          // eslint-disable-next-line
          console.log(`No type on object or unknown way to handle type: ${key}`, obj[lKey]);
        }
      }
    } else {
      // eslint-disable-next-line
      console.log(`${key} is not an object, so no way to handle that.`);
    }
  });
  return newObj;
};
export default FormContext;
