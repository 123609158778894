import { Check } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { FormContext, GetFormData, GetPathObject } from '../../contexts/FormContext';
//import { useUserContext } from '../../contexts/userContext';
import {
  useFileDownloadResponseHandler,
  useGetServiceInfo,
  useUpdateConfigurationMutation,
} from '../../features/serviceConfiguration/api';
import { OnChangeValueType, SchemaComponent, SchemaComponentWithKey } from '../../types/schema';
import ComponentChooser from './ComponentChooser';
import { ServiceInfo } from '/src/types/site';

export interface SubPartProps {
  id: string;
  comp: SchemaComponent;
  required: boolean;
}

const SubPart = ({ id, comp }: SubPartProps) => {
  const [data, setData] = useState<SchemaComponent>({ ...comp.schema });
  const navigate = useNavigate();
  const [configUrl, setConfigUrl] = useState<string | null>(null);
  const [partConfigUrl, setPartConfigUrl] = useState<string | null>(null);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const { siteId, serviceId, sub } = useParams();
  const serviceInfo = useGetServiceInfo<ServiceInfo>(serviceId || '');
  const [showConfirmAction, setShowConfirmAction] = useState(false);
  const updateMutation = useUpdateConfigurationMutation();
  const sendPostAndDownloadFile = useFileDownloadResponseHandler();
  const queryClient = useQueryClient();
  const { enqueueSnackbar: showSnack } = useSnackbar();

  const postData = async () => {
    const dataToPost = GetFormData(data);
    const saveUrl = `${configUrl}`;

    if (comp.schema?.responseType?.value === 'FileDownload') {
      await sendPostAndDownloadFile({
        data: dataToPost,
        url: saveUrl,
        fallbackName: 'categories.csv',
      });
    } else {
      try {
        await updateMutation.mutateAsync({ data: dataToPost, url: saveUrl });
      } catch (err) {
        showSnack('Could not send post, the server responded with an error. Try again later.', {
          variant: 'warning',
        });
      }
      await queryClient.invalidateQueries(['serviceConfiguration', partConfigUrl]);
      if (data.redirect?.value) {
        const newUrl = `/services/${siteId}/${serviceId}${data.redirect.value as string}`;
        navigate('/');
        navigate(newUrl, { replace: false });
        showSnack('Subpart was posted', { variant: 'success' });
      } else {
        showSnack('Subpart was posted', { variant: 'success' });
      }
    }

    setShowConfirmAction(false);
  };

  const onSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (data?.warning?.value) {
      setShowConfirmAction(true);
    } else {
      await postData();
    }
  };

  const closeDialog = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowConfirmAction(false);
  };

  useEffect(() => {
    if (serviceInfo.isSuccess) {
      const url = `${serviceInfo.data.basePath}${serviceInfo.data.configurationPath}${
        data?.configurationEndpoint?.value as string
      }/${siteId}`;
      setConfigUrl(url);

      const partUrl = `${serviceInfo.data.basePath}${serviceInfo.data.configurationPath}${
        sub ? `/${sub}` : ''
      }/${siteId}`;
      setPartConfigUrl(partUrl);

      document.title = `${serviceInfo.data.name} | Simply by Coor`;
    }
  }, [serviceInfo]);

  const dirtyStyle = formIsDirty
    ? { backgroundColor: 'rgba(255, 0, 0, 0.05)', border: '1px solid #faa' }
    : {};

  return (
    <FormContext.Provider
      value={{
        handleChange: (valueid, val, wholeObject = false) => {
          if (wholeObject) {
            const objTmpData: SchemaComponent = { ...data };
            (objTmpData[id as keyof SchemaComponent] as OnChangeValueType) = val;
            setData(objTmpData);
          } else {
            const po = GetPathObject(valueid, data) as SchemaComponentWithKey;
            if (po) {
              po.value = val as SchemaComponent;
              setData(data);
            } else {
              // eslint-disable-next-line no-console
              console.log('Could not find path in data to update value');
            }
          }
        },
        setFormIsDirty,
      }}>
      <Box sx={{ ...dirtyStyle, p: 2, my: 2, borderRadius: 2, border: '1px solid rgba(0,0,0,.2)' }}>
        <Box>
          <Typography variant="h6" sx={{ mb: 1 }}>
            {comp.title}
          </Typography>
        </Box>
        <Box>
          {Object.keys(comp.schema!).map((obj) => {
            const lData = comp?.schema![obj];
            return (
              <ComponentChooser
                key={obj}
                id={obj}
                comp={lData}
                required={lData?.optional === false ? true : false}
              />
            );
          })}
        </Box>
        <Box sx={{ p: 1 }}>
          <Stack direction="row" spacing={4} justifyContent="end">
            <Button
              variant="contained"
              color="success"
              type="submit"
              endIcon={<Check />}
              onClick={onSubmit}>
              {(comp?.schema?.buttonText?.value as string) ?? 'Save'}
            </Button>
          </Stack>
        </Box>
      </Box>
      <Dialog
        open={showConfirmAction}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-discription">
        <DialogTitle id="alert-dialog-title">Warning</DialogTitle>
        <DialogContent>{(data?.warning?.value as string) || 'Unknown error'}</DialogContent>
        <DialogActions>
          <Button variant="contained" color="info" onClick={closeDialog}>
            No
          </Button>
          <Button variant="contained" color="success" onClick={postData}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </FormContext.Provider>
  );
};

export default SubPart;
