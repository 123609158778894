import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import { Autocomplete, FormControl, IconButton, MenuItem, Skeleton, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { matchRoutes, useNavigate } from 'react-router-dom';

import { useUserContext } from '../contexts/userContext';
import { useGetSites } from '../features/Sites/api';
import { Site } from '../types/site';

interface AutoCompleteValues {
  label: string;
  value: string;
}

const SiteChanger = () => {
  const emptySite = {
    label: 'Select a site',
    value: '-1',
  };

  const { setCurrentSite } = useUserContext();
  const [hasRouteSiteId, setHasRouteSiteId] = useState(false);
  const { currentSite: tmpCurrentSite } = useUserContext();
  const currentSite = tmpCurrentSite as Site;

  const parts = matchRoutes([{ path: '/sites/:siteId/*' }, { path: '/services/:siteId/*' }], location) ?? [];

  let siteId: string = '-1';
  if (parts[0] && 'siteId' in parts[0]) {
    siteId = parts[0].siteId as string;
    setHasRouteSiteId(true);
  } else if (currentSite && currentSite.id) {
    siteId = currentSite.id;
  }

  const [lCurrentSite, setLCurrentSite] = useState<AutoCompleteValues>(emptySite);
  const [lSites, setLSites] = useState<AutoCompleteValues[]>([emptySite]);
  const { data, isLoading, error } = useGetSites();
  const navigate = useNavigate();

  const handleChange = (e: React.SyntheticEvent<Element, Event>, value: AutoCompleteValues | null) => {
    const site = data?.find((s) => s.id === value?.value);
    if (!site) {
      setCurrentSite('-1');
      navigate(`/sites/new`);
    } else {
      navigate(`/sites/${site.id}`);
    }
    setCurrentSite(site!);
  };

  useEffect(() => {
    const d = data?.map((s) => {
      return { label: s.name, value: s.id };
    });
    setLSites(d as AutoCompleteValues[]);
    if (siteId && data) {
      const cs = data.find((s) => s.id === siteId);
      if (cs) {
        setLCurrentSite({ label: cs.name, value: cs.id! });
      } else {
        setLCurrentSite(emptySite);
      }
    }
  }, [data, siteId]);

  return error ? (
    <p>Error fetching sites</p>
  ) : (
    <Stack sx={{ minWidth: 120, px: 2, mt: 2 }} direction="row" spacing={1}>
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          <FormControl fullWidth size="small">
            {lSites && lSites.length > 0 && (
              <Autocomplete
                disablePortal
                size="small"
                value={lCurrentSite}
                isOptionEqualToValue={(option, value) => {
                  return option.value === value.value;
                }}
                filterOptions={(s, d) => {
                  const a = s.filter((c) => {
                    if (!d.inputValue) {
                      return c;
                    }
                    return c.label.toLowerCase().includes(d.inputValue.toLowerCase());
                  });
                  return a;
                }}
                options={lSites}
                renderOption={(props, option) => {
                  return (
                    <MenuItem {...props} key={option.value}>
                      {option.label}
                    </MenuItem>
                  );
                }}
                onChange={handleChange}
                renderInput={(params) => {
                  return <TextField {...params} label="Select a site" />;
                }}
                fullWidth
                id="siteChangerSelect"></Autocomplete>
            )}
          </FormControl>
          {!hasRouteSiteId && siteId !== '-1' && (
            <IconButton onClick={() => navigate(`/sites/${siteId}`)} color="primary">
              <BrowserUpdatedIcon />
            </IconButton>
          )}
        </>
      )}
    </Stack>
  );
};

export default SiteChanger;
