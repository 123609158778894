import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { SRMetaData } from '../Metadata/Metadata';
import { useLocationTabContext } from './AddEditLocation';
import { useMetaData } from './api';
import { useLocationContext } from './Locations';

const Metadata = () => {
  const { metaData, baseData: location, setBaseData } = useLocationTabContext();
  const { siteId } = useLocationContext();
  const { data: allMetaData, isLoading } = useMetaData(siteId);

  const [localAllMetaData, setLocalAllMetaData] = useState<SRMetaData[]>([]);

  const addMetadata = (add: SRMetaData) => {
    const tmpData = { ...location, metadata: [...location.metadata, add] };
    setBaseData(tmpData);
  };

  const removeMetadata = (id: SRMetaData) => {
    const tmpData = { ...location, metadata: location.metadata.filter((m) => m.id !== id.id) };
    setLocalAllMetaData([...localAllMetaData, id]);
    setBaseData(tmpData);
  };

  useEffect(() => {
    if (allMetaData) {
      const filtered = allMetaData.filter((d) => {
        return !metaData.find((m) => m.id === d.id);
      });
      setLocalAllMetaData(filtered);
    }
  }, [allMetaData, metaData]);

  return (
    <Box sx={{ my: 2 }}>
      <Stack direction="row">
        <Box sx={{ flex: '0 0 50%' }}>
          <Typography component="span" variant="subtitle1" sx={{ display: 'block' }}>
            Available metadata
          </Typography>
          <Box sx={{ flex: '1 1 auto' }}>
            {isLoading && <Typography>Loading metadata...</Typography>}
            {localAllMetaData?.map((d) => {
              return (
                <Chip
                  deleteIcon={<AddCircleIcon />}
                  color="info"
                  key={`${d.id}`}
                  label={d.name}
                  variant="outlined"
                  onDelete={() => addMetadata(d)}
                  sx={{ mb: 1, mr: 1 }}
                />
              );
            })}
          </Box>
        </Box>
        <Box sx={{ flex: '0 0 50%' }}>
          <Typography component="span" variant="subtitle1" sx={{ display: 'block' }}>
            Connected metadata
          </Typography>
          <Box sx={{ flex: '1 1 auto' }}>
            <Stack direction="row" spacing={0} sx={{ flexWrap: 'wrap' }}>
              {metaData?.map((d) => {
                return (
                  <Chip
                    color="success"
                    key={`${d.id}`}
                    label={d.name}
                    variant="outlined"
                    onDelete={() => removeMetadata(d)}
                    sx={{ mb: 1, mr: 1 }}
                  />
                );
              })}
            </Stack>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default Metadata;
