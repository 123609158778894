import {
  Card,
  CardContent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';

import { Fragment } from 'react';

import Collapsible from './Collapsible';
// eslint-disable-next-line
import ComponentChooser from './ComponentChooser';

import { SchemaComponent } from '../../types/schema';

interface ListProps {
  comp?: SchemaComponent;
  id: string;
  isSub?: boolean;
}

const List = ({ comp, id, isSub = false }: ListProps) => {
  // Warning: here be dragons...
  if (!comp) {
    return null;
  }
  const { data } = comp;
  const dirty = false;
  return (
    <Card
      sx={{ my: isSub ? 0 : 2 }}
      variant={!isSub ? 'elevation' : undefined}
      elevation={!isSub ? 2 : 0}>
      <CardContent
        sx={{
          p: isSub ? 0 : 1,
          border: isSub ? 'none' : '',
          boxShadow: 'none',
          '&:last-child': { pb: isSub ? 1 : 1, px: isSub ? 4 : 0 },
        }}>
        <input type="hidden" className={dirty ? 'dirty' : ''} />
        <Collapsible
          summary={
            <Stack direction="row" justifyContent="space-between" sx={{ flex: '1 1 auto' }}>
              <Typography
                component="span"
                variant={isSub ? 'body1' : 'h6'}
                sx={{ fontWeight: 'bold', px: 2 }}>
                {comp.title}
              </Typography>
            </Stack>
          }
          open={false}>
          <Table size="small">
            <TableBody>
              {(data as SchemaComponent[])?.map((keyvalue, index) => {
                const kvKey = `${keyvalue as string}-${index}`;
                const additionalRows: JSX.Element[] = [];
                const hasSubList = Object.entries(keyvalue).some(([, val]) => {
                  return (val as SchemaComponent).type === 'List';
                });

                const table = (
                  <TableRow>
                    {Object.keys(keyvalue).map((kv) => {
                      const key = `${id}.data.[${index}].${kv}`;
                      const lKeyValue = keyvalue[kv as keyof SchemaComponent] as SchemaComponent;
                      const noShow =
                        lKeyValue.type === 'Hidden' ||
                        ((lKeyValue.type as string).toLowerCase() === 'label' && !lKeyValue.value)
                          ? {
                              width: '0px',
                              overflow: 'hidden',
                              padding: 0,
                              verticalAlign: 'middle',
                            }
                          : {};

                      if (kv === 'description') {
                        return null;
                      }

                      if (lKeyValue.type === 'List') {
                        additionalRows.push(
                          <TableRow
                            key={`sublist-${key}`}
                            sx={{ boxShadow: 'none', '&:last-child > td': { borderBottom: 0 } }}>
                            <TableCell colSpan={Object.keys(keyvalue).length} sx={{ p: 0 }}>
                              <List comp={lKeyValue} id={`${key}`} isSub />
                            </TableCell>
                          </TableRow>,
                        );
                      } else {
                        return (
                          <TableCell
                            key={key}
                            sx={{
                              verticalAlign: 'middle',
                              borderBottom: hasSubList ? 'none' : '',
                              ...noShow,
                            }}>
                            {(lKeyValue.type as string).toLowerCase() === 'label' ? (
                              <h3>{lKeyValue.value as string}</h3>
                            ) : (
                              <ComponentChooser
                                comp={lKeyValue}
                                id={key}
                                key={key}
                                required={lKeyValue?.optional === false ? true : false}
                              />
                            )}
                          </TableCell>
                        );
                      }
                      return null;
                    })}
                  </TableRow>
                );
                return (
                  <Fragment key={`fragment-key-${kvKey}`}>
                    {table}
                    {additionalRows.map((d) => {
                      return d;
                    })}
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </Collapsible>
      </CardContent>
    </Card>
  );
};

export default List;
