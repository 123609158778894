import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { Context, useContext, useEffect, useState } from 'react';

import { FormContext, FormContextType } from '../../../../contexts/FormContext';
import { SchemaComponent } from '../../../../types/schema';
import Collapsible from '../../Collapsible';
import { ComponentBase } from '../../ComponentChooser';
import Mappings, { MappingProperties } from './Mappings';
import Sortable from './Sortable';

export const languages = ['en', 'sv', 'no', 'da', 'fi'] as const;
export type SelectLangauges = (typeof languages)[number];

export interface CustomFieldSelectSchema {
  key: string;
  translations: Record<SelectLangauges, string>;
}
export interface CustomFieldSchema {
  id: SchemaComponent;
  name?: SchemaComponent;
  title: SchemaComponent;
  fieldType: SchemaComponent;
  required: SchemaComponent;
  value?: SchemaComponent;
  mappings?: CustomFieldMapping[];
  translations: Record<string, string | string[]>;
  values: CustomFieldSelectSchema[];
  error?: boolean;
}

export interface CustomFieldMapping {
  id?: string;
  associatedType: string;
  associatedId: string;
  title: string;
}

export interface CustomFieldCategory {
  id: string;
  description: string;
  properties: MappingProperties[];
  title: string;
}

export interface ICategoryType {
  key: string;
  value: string;
  id: string;
}

export const categoryTypes: ICategoryType[] = [
  { key: '-1', value: 'Select type', id: '-1' },
  {
    key: 'servicerequest_type_fault',
    value: 'Fault',
    id: '657940a0-5279-4d8a-9afc-16a9ee2e59da',
  },
  {
    key: 'servicerequest_type_order',
    value: 'Order',
    id: '9a6d5817-ab90-4f16-85d4-7cad27865852',
  },
  {
    key: 'servicerequest_type_feedback',
    value: 'Feedback',
    id: '18fdbfe1-b11c-4155-9425-a070e7a33511',
  },
  {
    key: 'servicerequest_type_question',
    value: 'Question',
    id: '268e0b8f-221d-468a-a752-4d31213eec6a',
  },
];

const CustomFields = ({ id, comp }: ComponentBase) => {
  const [values, setValues] = useState<CustomFieldSchema[]>([]);
  const [dirty, setDirty] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const { enqueueSnackbar: showSnack } = useSnackbar();
  const { handleChange, setFormIsDirty } = useContext<FormContextType>(
    FormContext as unknown as Context<FormContextType>,
  );

  const dirtyStyle = dirty ? { backgroundColor: 'rgba(255, 0, 0, 0.05)' } : {};
  const valueTemplate: CustomFieldSchema = {
    title: {
      type: 'TextBox',
      title: 'Title of the field',
      value: '',
    },
    id: {
      title: '',
      type: 'Hidden',
    },
    fieldType: {
      type: 'TextBox',
      title: 'The type of of the field',
      value: 'TextBox',
    },
    required: {
      type: 'CheckBox',
      title: 'Is the field required',
      value: true,
    },
    translations: {},
    mappings: [],
    values: [],
  };

  const createNewCustomField = () => {
    const tmpValues = [...values];
    tmpValues.push(valueTemplate);
    setValues(tmpValues);
  };

  const onChangeTranslation = (index: number, lang: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const tmpValues = [...values];
    if (tmpValues[index] && tmpValues[index].translations) {
      // if (!tmpValues[index].translations[lang]) {
      //   tmpValues[index].translations[lang] = {};
      // }
      tmpValues[index].translations[lang] = e.target.value;
      setValues(tmpValues);
      setDirty(true);
    }
  };

  const addSelectRow = (index: number) => () => {
    const tmpValues = [...values];
    if (!Array.isArray(tmpValues[index].values)) {
      tmpValues[index].values = [];
      tmpValues[index].error = false;
    }
    tmpValues[index].values = [
      ...tmpValues[index].values,
      {
        key: '',
        translations: { sv: '', no: '', en: '', fi: '', da: '' },
      },
    ];
    setDirty(true);
    setValues(tmpValues);
  };

  const handleChangeOrder = (index: number) => (newValues: CustomFieldSelectSchema[]) => {
    const tmpValues = [...values];
    tmpValues[index].values = newValues;
    setValues(tmpValues);
    setDirty(true);
  };

  const onChangeSelectValues =
    (index: number) => (idx: number, lang: SelectLangauges) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const tmpValues = [...values];
      tmpValues[index].values[idx].translations[lang] = e.target.value;
      setDirty(true);
      setValues(tmpValues);
    };

  const onDeleteSelectValue = (index: number) => (idx: number) => {
    const tmpValues = [...values];
    const newValues = tmpValues[index].values.filter((_, i) => i !== idx);
    tmpValues[index].values = newValues;
    setDirty(true);
    setValues(tmpValues);
  };

  const onChange = (index: number, path: keyof CustomFieldSchema) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const tmpValues: CustomFieldSchema[] = [...values];
    setDirty(true);
    e.preventDefault();
    if (tmpValues[index] && tmpValues[index][path]) {
      (tmpValues[index][path] as SchemaComponent).value = e.target.value;
      setValues(tmpValues);
    }
  };
  const changeRequired = (index: number) => (e: React.SyntheticEvent<Element, Event>) => {
    const tmpValues = [...values];
    tmpValues[index].required.value = (e.target as HTMLInputElement).checked;
    setValues(tmpValues);
    setDirty(true);
  };

  const confirmDeleteField = (index: number) => () => {
    setShowConfirmDelete(true);
    setDeleteIndex(index);
  };
  const deleteField = () => {
    if (deleteIndex !== null) {
      const tmpValues = [...values];
      tmpValues.splice(deleteIndex, 1);
      setValues(tmpValues);
      setDeleteIndex(null);
    }
    setDirty(true);
    setShowConfirmDelete(false);
  };

  const closeDialog = () => {
    setDeleteIndex(null);
    setShowConfirmDelete(false);
  };
  const updateData = () => {
    let hasError = false;
    const tmpValues = [...values];
    for (const value of tmpValues) {
      if (value.fieldType.value === 'Select' && (!Array.isArray(value.values) || value.values?.length < 1)) {
        hasError = true;
        value.error = true;
      } else {
        value.error = false;
      }
    }
    if (hasError) {
      setValues(tmpValues);
      showSnack('Some of the custom fields has errors, update and try again.', {
        variant: 'warning',
      });
    } else {
      setDirty(false);
      setFormIsDirty(false);
      const d = {
        ...comp,
        value: values.map((d) => {
          delete d.error;
          return d;
        }),
      };

      handleChange(id, d as SchemaComponent, true);
      showSnack('Updated custom fields, you can now save your changes to the server!', {
        variant: 'success',
      });
    }
  };
  const updateMappings = (index: number) => (mappings: CustomFieldMapping[]) => {
    const tmpValues = [...values];
    tmpValues[index].mappings = mappings;
    setValues(tmpValues);
    setDirty(true);
  };

  useEffect(() => {
    setValues(
      (comp.value as CustomFieldSchema[])?.map((v) => {
        return { ...v };
      }),
    );
  }, [comp.value]);

  return (
    <>
      <Box sx={dirtyStyle}>
        {values &&
          values.map((data, index) => {
            const key = `custom-fields-${(data.id?.value as string) || index}`;
            const showOpen = values.length < 4;
            return (
              <Box
                sx={{
                  my: 2,
                  border: (theme) => `1px solid ${theme.palette.divider}`,
                  borderRadius: '5px',
                }}
                key={key}>
                <Stack
                  spacing={2}
                  direction="row"
                  justifyContent="flex-start"
                  sx={{ background: 'transparent', padding: 0.5 }}>
                  <Collapsible
                    open={showOpen}
                    key={key}
                    sx={{ flex: '1' }}
                    summary={
                      <Typography component="span" variant="button" sx={{ flex: '1 1 auto' }}>
                        {(data?.title?.value as string) || 'Name:'}
                      </Typography>
                    }>
                    <Stack direction="row" justifyContent="space-between" sx={{ flex: '1 1 auto' }}>
                      <FormControl sx={{ flex: '1 1 auto' }}>
                        <TextField
                          size="small"
                          label="Name"
                          placeholder="Add a name for the custom field"
                          value={data?.title?.value || ''}
                          onChange={onChange(index, 'title')}
                          required
                        />
                      </FormControl>

                      <FormControl sx={{ flex: '0 1 auto', ml: 1, minWidth: '150px' }}>
                        <TextField
                          select={true}
                          size="small"
                          label="Type"
                          placeholder="Select the type for this custom field"
                          value={data?.fieldType?.value || ''}
                          onChange={onChange(index, 'fieldType')}
                          required>
                          {['TextBox', 'Label', 'Select'].map((d) => {
                            return (
                              <MenuItem key={d} value={d}>
                                {d}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </FormControl>

                      <FormControlLabel
                        checked={data.required.value as boolean}
                        onChange={changeRequired(index)}
                        control={
                          <Checkbox
                            checked={data.required.value as boolean}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label="Required"
                        sx={{ flex: '0 1 auto', pl: 2, alignSelf: 'flex-start' }}
                      />
                      <FormControl sx={{ flex: '0 1 auto' }}>
                        <IconButton
                          aria-label="Delete this custom field"
                          onClick={confirmDeleteField(index)}
                          color="error"
                          defaultValue={index}
                          sx={{ ml: 1 }}>
                          <DeleteIcon />
                        </IconButton>
                      </FormControl>
                    </Stack>
                    <Collapsible
                      open={false}
                      key={key}
                      sx={{ flex: '1', '::before': { background: 'none' } }}
                      summary={
                        <Typography component="span" variant="h6" sx={{ flex: '1 1 auto' }}>
                          Translations
                        </Typography>
                      }>
                      <Stack direction="row" spacing={2}>
                        {languages.map((lang) => {
                          return (
                            <Box key={lang}>
                              <Stack direction="column" spacing={2}>
                                <Box>{lang}</Box>
                                <FormControl sx={{ flex: '1 1 auto' }}>
                                  <TextField
                                    size="small"
                                    value={data.translations?.[lang] || ''}
                                    label={`Translation for "${data.title?.value as string}"`}
                                    placeholder={`Translation for "${data.title?.value as string}"`}
                                    onChange={onChangeTranslation(index, lang)}
                                  />
                                </FormControl>
                              </Stack>
                            </Box>
                          );
                        })}
                      </Stack>
                    </Collapsible>

                    {data?.fieldType?.value === 'Select' && (
                      <Sortable
                        data={data}
                        items={data.values}
                        addRow={addSelectRow(index)}
                        onChangeSelectValues={onChangeSelectValues(index)}
                        onUpdateSortOrder={handleChangeOrder(index)}
                        onDeleteSelectValue={onDeleteSelectValue(index)}
                      />
                    )}

                    <Mappings
                      mappings={data.mappings}
                      onChange={updateMappings(index)}
                      categories={comp.categories as Record<string, CustomFieldCategory[]>}
                    />
                  </Collapsible>
                </Stack>
              </Box>
            );
          })}

        <Stack
          direction="row"
          justifyContent="flex-start"
          gap="1rem"
          sx={{ mt: 1, pt: 2, borderTop: (theme) => `1px solid ${theme.palette.divider}` }}>
          <Button
            variant="contained"
            aria-label="Add a new Custom field"
            onClick={createNewCustomField}
            color="info"
            sx={{ ml: 1 }}>
            <AddIcon />
            Add new
          </Button>
          <Button
            sx={{ ml: 'auto' }}
            color="success"
            variant="contained"
            disabled={!dirty}
            onClick={updateData}
            endIcon={<SaveAltIcon />}>
            Save custom fields
          </Button>
        </Stack>
      </Box>

      <Dialog open={showConfirmDelete} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-discription">
        <DialogTitle id="alert-dialog-title">Delete customer</DialogTitle>
        <DialogContent>Are you sure you want to delete this customer?</DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={closeDialog}>
            Cancel
          </Button>
          <Button variant="outlined" color="warning" onClick={deleteField}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomFields;
