import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Switch,
  TableCell,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import { useState } from 'react';
import { SRLocation, locationGuids, useUpdateLocationMutation } from './api';

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(even)': {
    backgroundColor: 'rgba(2, 136, 209, 0.3)',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: 'absolute',
  '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
    top: 0,
    right: theme.spacing(2),
  },
  '& .MuiFab-primary': { width: 38, height: 38, backgroundColor: '#0288d1', color: '#fff' },
}));
interface RenderLocationProps {
  level?: number;
  handleEditLocation: (location: SRLocation, parentId: string | null) => void;
  handleChangeParentId: (parentId: SRLocation) => void;
  handleDeleteLocation: (location: SRLocation) => void;
  location: SRLocation;
  siteId: string;
}

const RenderLocation = ({
  location,
  siteId,
  handleEditLocation,
  handleChangeParentId,
  handleDeleteLocation,
}: RenderLocationProps) => {
  const {
    externalId,
    title,
    locationType,
    hasChildren,
    isSelectable,
    descriptionRequired,
    isHidden,
  } = location;
  const updateLocationMutation = useUpdateLocationMutation(siteId);

  const [lIsSelectable, setLIsSelectable] = useState(isSelectable);
  const [lDescriptionRequired, setLDescriptionRequired] = useState(descriptionRequired);
  const [lIsHidden, setLIsHidden] = useState(isHidden);

  const actions = [
    {
      icon: <EditIcon color="info" />,
      name: 'Edit location',
      onClick: () => {
        handleEditLocation(location, null);
      },
    },
    {
      icon: <AddCircleIcon color="info" />,
      name: 'Add sub location',
      onClick: () => {
        handleEditLocation({} as SRLocation, location.id || null);
      },
    },
    {
      icon: <DeleteIcon color="error" />,
      name: 'Delete location',
      onClick: () => {
        handleDeleteLocation(location);
      },
    },
  ];

  return (
    <StyledTableRow
      sx={{
        cursor: hasChildren ? 'pointer' : 'default',
        '&:hover': {
          backgroundColor: (theme) => (hasChildren ? theme.palette.action.hover : 'none'),
        },
      }}
      onClick={() => {
        if (hasChildren) {
          handleChangeParentId(location);
        }
      }}>
      <TableCell>{hasChildren && <AccountTreeIcon color="info" />}</TableCell>
      <TableCell>
        <Typography variant="body1" sx={{ py: 1 }}>
          {externalId}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {title}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          {locationGuids.find((d) => d.key === locationType)?.value}
        </Typography>
      </TableCell>
      <TableCell>
        <Switch
          color="info"
          value={lIsHidden}
          checked={lIsHidden}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            setLIsHidden(e.target.checked);
            updateLocationMutation.mutate({
              ...location,
              isHidden: e.target.checked,
            });
          }}
        />
      </TableCell>
      <TableCell>
        <Switch
          color="info"
          checked={lIsSelectable}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            setLIsSelectable(e.target.checked);
            updateLocationMutation.mutate({
              ...location,
              isSelectable: e.target.checked,
            });
          }}
        />
      </TableCell>
      <TableCell>
        <Switch
          color="info"
          value={lDescriptionRequired}
          checked={lDescriptionRequired}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            setLDescriptionRequired(e.target.checked);
            updateLocationMutation.mutate({
              ...location,
              descriptionRequired: e.target.checked,
            });
          }}
        />
      </TableCell>

      <TableCell align="right" sx={{ position: 'relative' }}>
        <StyledSpeedDial
          ariaLabel="Tools for this location"
          icon={<SpeedDialIcon />}
          direction="left">
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                action.onClick();
              }}
            />
          ))}
        </StyledSpeedDial>
      </TableCell>
    </StyledTableRow>
  );
};

export default RenderLocation;
