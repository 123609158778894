import AddIcon from '@mui/icons-material/Add';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import fileDownload from 'js-file-download';
import { useSnackbar } from 'notistack';

import React, { createContext, useEffect, useState } from 'react';

import { FileDownloadTwoTone } from '@mui/icons-material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Pagination from '../QR/Pagination';
import AddEditLocationForm from './AddEditLocation';
import {
  SRLocation,
  useAddLocationMutation,
  useDeleteLocationMutation,
  useExportLocationMutation,
  useGetLocations,
  useUpdateLocationMutation,
} from './api';
import Import from './Import';
import RenderLocation from './RenderLocation';

/* TODO: Check typings whole file */
interface LocationBase {
  type?: string | LocationValue;
  value?: string | string[] | boolean;
  key?: string;
}

export interface LocationValue extends LocationBase {
  optional?: boolean;
  title?: string;
  type: string;
  value?: string | string[] | boolean;
  data?: string | LocationArray[];
  toolTip?: string;
  default?: string | boolean;
}
export interface LocationArray {
  key: string;
  value: string;
  selected?: boolean | string;
}

export interface LocationFormat extends Omit<LocationBase, 'value'> {
  descriptionRequired: string | LocationValue;
  externalId: string | LocationValue;
  externalSiteId: string | LocationValue;
  id: string | LocationValue;
  locationTitle?: string | LocationValue;
  locationType: string | LocationValue;
  name: LocationValue | string;
  value: LocationFormat[];
  parent?: string;
  mRenderId?: string;
  desc?: string | LocationValue;
}

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export interface LocationFormContext {
  setShowConfirmDelete: (showConfirm: boolean) => void;
  handleShowDialog: (cb: () => void) => void;
  siteId: string;
}

export const LocationContext = createContext<LocationFormContext | null>(null);

export const useLocationContext = () => {
  const ctx = React.useContext(LocationContext);
  if (ctx === null) {
    throw new Error('useLocationContext must be used inside a LocationContext');
  }
  return ctx;
};

const Locations = () => {
  let num = 10;
  if (window.localStorage) {
    const lsVal = window.localStorage.getItem('locationNumPerPage');
    if (lsVal !== null) {
      num = parseInt(lsVal, 10) || 10;
    }
  }

  const { enqueueSnackbar: showSnack } = useSnackbar();
  const { siteId: tSiteId } = useParams();
  const siteId = tSiteId as string;
  const [deleteSubLocation, setDeleteSubLocation] = useState<null | (() => void)>(null);

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [lLocations, setLLocations] = useState<SRLocation[]>([]);
  const [pageSize, setPageSize] = useState<number>(num);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [editLocation, setEditLocation] = useState<SRLocation | null>(null);
  const [showEditLocation, setShowEditLocation] = useState(false);
  const [subParentId, setSubParentId] = useState<string | null>(null);
  const [rootParentId, setRootParentId] = useState<SRLocation[] | null>(null);

  const updateLocationMutation = useUpdateLocationMutation(siteId);
  const navigate = useNavigate();
  const addLocationMutation = useAddLocationMutation(siteId);
  const deleteLocationMutation = useDeleteLocationMutation(siteId);
  const exportMutation = useExportLocationMutation(siteId);

  const { isLoading: loadingLocations, data: llc } = useGetLocations(
    siteId,
    currentPage,
    pageSize,
    rootParentId?.at(-1)?.id || null,
  );

  const handleShowDialog = (cb: () => void) => {
    setDeleteSubLocation(() => cb);
    setShowConfirmDelete(true);
  };
  const handleShowImport = () => {
    setShowFileUpload(true);
  };

  const handleChooseParentId = (parent: SRLocation) => {
    const tmpArr = Array.isArray(rootParentId) ? [...rootParentId] : [];
    tmpArr.push(parent);
    setRootParentId(tmpArr);
    setCurrentPage(0);
    navigate(`#${tmpArr.at(-1)?.id}`, { relative: 'route' });
  };

  const deleteLocation = (dLocation: SRLocation) => {
    handleShowDialog(removeLocation(dLocation));
  };

  const removeLocation = (dLocation: SRLocation) => async () => {
    const res = await deleteLocationMutation.mutateAsync(dLocation.id as string);
    if (res.status === 204) {
      showSnack('The location was deleted.', { variant: 'success' });
    } else {
      showSnack('Location could not be deleted, try again later. 😢', { variant: 'warning' });
    }
  };
  const currentLoc = useLocation();

  useEffect(() => {
    const cl = currentLoc.hash.slice(1);
    const a = rootParentId?.find((l) => l.id === cl);

    if (!a) {
      setRootParentId(null);
      if (currentLoc.hash) {
        //Failsafe so we don't get stuck in a loop when the hash is empty
        navigate('#', { relative: 'path' });
      }
    } else if (Array.isArray(rootParentId)) {
      const i = rootParentId?.findIndex((l) => l.id === cl);
      const tmpArr = [...rootParentId];
      tmpArr.splice(i + 1, tmpArr.length);
      setRootParentId(tmpArr);
    }
  }, [currentLoc]);

  const handleBreadCrumbs = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    newParent: SRLocation | null,
  ) => {
    event.preventDefault();
    setCurrentPage(0);
    if (newParent === null) {
      setRootParentId(null);
      navigate('#', { relative: 'path' });
    } else if (Array.isArray(rootParentId)) {
      const tmpArr = [...rootParentId];
      const index = tmpArr.findIndex((l) => l.id === newParent?.id);
      if (index > -1) {
        tmpArr.splice(index + 1, tmpArr.length);
      }
      setRootParentId(tmpArr);
      navigate(`#${newParent.id}`, { relative: 'path' });
    }
  };

  const handleDeleteSubLocation = () => {
    if (deleteSubLocation) {
      deleteSubLocation();
      setDeleteSubLocation(null);
    }
    setShowConfirmDelete(false);
  };

  const closeDialog = () => {
    setShowConfirmDelete(false);
  };

  const handleExport = async () => {
    const exportData = await exportMutation.mutateAsync();
    fileDownload(exportData, 'locations.csv');
  };

  const handleRowChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const count = parseInt(e.target.value, 10);
    if (window.localStorage) {
      window.localStorage.setItem('locationNumPerPage', count.toString());
    }
    setPageSize(count);
    setCurrentPage(0);
  };
  const handlePageChange = (e: React.ChangeEvent<unknown> | null, value: number) => {
    setCurrentPage(value);
  };

  const handleOnEditLocation = (lLocation: SRLocation, parentId: string | null = null) => {
    setSubParentId(parentId);
    setEditLocation(lLocation);
    setShowEditLocation(true);
  };

  const handleSaveUpdateLocation = async (data: SRLocation) => {
    if (data.id) {
      const res = await updateLocationMutation.mutateAsync(data);
      if (res.status === 200) {
        showSnack('The location was updated successfully. 😍', { variant: 'success' });
      } else {
        showSnack('Location could not be updated, try again later. 😢', { variant: 'warning' });
      }
    } else {
      const res = await addLocationMutation.mutateAsync(data);
      if (res.status === 200) {
        showSnack('The location was saved. 😍', { variant: 'success' });
      } else {
        showSnack('Location could not be saved, try again later. 😢', { variant: 'warning' });
      }
    }
  };

  useEffect(() => {
    if (!loadingLocations && llc) {
      setLLocations(llc.locations);
    }
  }, [loadingLocations, llc]);

  return (
    <LocationContext.Provider value={{ setShowConfirmDelete, handleShowDialog, siteId }}>
      <Card sx={{ my: 2 }} variant="outlined">
        <CardContent sx={{ p: 1, '&:last-child': { pb: 0 } }}>
          <Box>
            <Stack direction="row" alignItems="center">
              <Box flex="1 1 auto">
                <Breadcrumbs aria-label="breadcrumb" sx={{ py: 1.5 }}>
                  <Link
                    underline="hover"
                    color={
                      Array.isArray(rootParentId) && rootParentId.length > 0 ? 'primary' : 'inherit'
                    }
                    href="#"
                    key={'theRoot'}
                    onClick={(e) => handleBreadCrumbs(e, null)}>
                    Home
                  </Link>

                  {Array.isArray(rootParentId) &&
                    rootParentId.map((lLoc, index) => {
                      if (index === rootParentId.length - 1) {
                        return (
                          <Typography variant="subtitle1" key={lLoc.id}>
                            {lLoc.title}
                          </Typography>
                        );
                      }
                      return (
                        <Link
                          underline="hover"
                          href="#"
                          key={lLoc.id}
                          onClick={(e) => handleBreadCrumbs(e, lLoc)}>
                          {lLoc.title}
                        </Link>
                      );
                    })}
                </Breadcrumbs>
              </Box>
              <Box flex="0 1 auto">
                {llc?.recordCount !== undefined && (
                  <Pagination
                    count={llc?.recordCount}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onHandleRowChange={handleRowChange}
                    onPageChange={handlePageChange}></Pagination>
                )}
              </Box>
            </Stack>

            <Table size="small">
              <TableHead>
                <TableRow sx={{ background: 'rgba(2, 136, 209, 0.3)' }}>
                  <TableCell></TableCell>
                  <TableCell align="left" width="20%">
                    External Id
                  </TableCell>
                  <TableCell width="40%">Name of location object</TableCell>
                  <TableCell width="20%">Type of location</TableCell>
                  <TableCell>Hidden</TableCell>
                  <TableCell>Selectable</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
                    Desc. required
                  </TableCell>
                  <TableCell align="right">Tools</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lLocations &&
                  lLocations.map((locData) => {
                    return (
                      <RenderLocation
                        siteId={siteId}
                        handleChangeParentId={handleChooseParentId}
                        handleDeleteLocation={deleteLocation}
                        key={locData.id}
                        handleEditLocation={handleOnEditLocation}
                        location={locData}
                      />
                    );
                  })}
                {lLocations.length < 1 && (
                  <TableRow>
                    <TableCell colSpan={6}>No locations found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {llc?.recordCount !== undefined && (
              <Pagination
                count={llc?.recordCount || 0}
                pageSize={pageSize}
                currentPage={currentPage}
                onHandleRowChange={handleRowChange}
                onPageChange={handlePageChange}></Pagination>
            )}
          </Box>
          <Stack
            direction="row"
            justifyContent="flex-start"
            gap="1rem"
            sx={{ my: 1, pt: 2, borderTop: (theme) => `1px solid ${theme.palette.divider}` }}>
            <Button
              onClick={() => {
                handleOnEditLocation({} as SRLocation, rootParentId?.at(-1)?.id || null);
              }}
              variant="contained"
              color="info"
              startIcon={<AddIcon />}>
              Location
            </Button>
            <Button
              variant="contained"
              color="info"
              onClick={handleShowImport}
              startIcon={<UploadFileIcon />}>
              Import from csv
            </Button>

            <Button
              variant="contained"
              color="info"
              onClick={handleExport}
              startIcon={<FileDownloadTwoTone />}>
              Export to csv
            </Button>
          </Stack>
        </CardContent>
      </Card>

      <Import
        showFileUpload={showFileUpload}
        setShowFileUpload={setShowFileUpload}
        siteId={siteId}
      />

      <Dialog
        open={showConfirmDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-discription">
        <DialogTitle id="alert-dialog-title">Delete sub location</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this location? It will also remove all sublocations and it
          can&apos;t be undone.
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="info" onClick={closeDialog}>
            Cancel
          </Button>
          <Button variant="outlined" color="warning" onClick={handleDeleteSubLocation}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <AddEditLocationForm
        show={showEditLocation}
        parentId={subParentId}
        onCancel={() => {
          setShowEditLocation(false);
          setEditLocation(null);
          setSubParentId(null);
        }}
        onSave={(data) => {
          if (data.title.length < 1 || data.externalId.length < 1 || data.locationType.length < 1) {
            showSnack('Please fill in all required fields', { variant: 'warning' });
          } else {
            setShowEditLocation(false);
            setSubParentId(null);
            void handleSaveUpdateLocation(data);
          }
        }}
        location={editLocation}
      />
    </LocationContext.Provider>
  );
};

export default Locations;
